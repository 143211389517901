


let user = localStorage.getItem('user');
let userinfo = { uid:'',isLogin:false,openid:'',name:'',img:''}


if(user){
    userinfo = JSON.parse(user)
}

export default {
    namespaced:true,
    state:{
        userinfo
    },
    mutations:{

        //设置
        setUser(state,payload){
            state.userinfo=payload;
            
        },
        //清空
        deleteUser(state){
            state.userinfo={
                uid:'',
                isLogin:false,
                openid:'',
                name:'',
                img:''
            };
            
        }
    },
    actions:{

    }
}