import Vue from 'vue'
import VueRouter from 'vue-router'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
	return routerPush.call(this, location).catch(err => {})
};

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect: 'app',
  },

  {
    path:'/app',
    name:'app',
    component:() => import ('../views/user/User.vue'),
    meta:{
      isLogin:true
    }
  },
  {
    path:'/stat',
    name:'stat',
    component:() => import ('../views/stat/Stat.vue'),
    meta:{
      isLogin:true
    }
  },
  {
    path:'/list',
    name:'list',
    component:() => import ('../views/list/List.vue'),
    meta:{
      isLogin:true
    }
  },
  {
    path:'/login',
    name:'login',
    component:() => import ('../views/login/Login.vue'),
  },
  {
    path:'/edit',
    name:'edit',
    component:() => import ('../views/edit/Useredit.vue'),
    meta:{
      isLogin:true
    }
  },
  {
    path:'/down',
    name:'down',
    component:() => import ('../views/down/Soft.vue')
  }

];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes,
});



export default router;
