import router from './index'
import store from '../store/index'

router.beforeEach((to,from,next)=>{
    if(to.meta.isLogin){

      if(store.state.user.userinfo.isLogin){
        next()
      }else{
        next('/login')
      }

    }else{
      next()
  
    }
})