<template>
  <div id="app">
    <Hdview />
    <router-view></router-view>
    <Ftview />
  </div>
</template>

<script>
import Hdview from './components/Header.vue';
import Ftview from './components/Footer.vue';
export default {
  components: {
    Hdview,
    Ftview

  },

}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  font-family: "微软雅黑";
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

#app {
  background: rgb(242, 243, 245);
  height: 100vh;
  font-family: 'pingfang SC','helvetica neue',arial,'hiragino sans gb','microsoft yahei ui','microsoft yahei',simsun,sans-serif;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
}



.content {
  padding-top: 80px;
  max-width: 1296px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .c_left {
    width: 100%;
    background: #FFF;
    padding: 16px;
    margin-right: 20px;
    /* 添加间距 */
    .applist {
    display: flex;
    align-items: center;
  

        .app-logo {
          width: 30px;
          height: 30px;
          border-radius: 6px;
        }

        .app-name {
          font-size: 20px;
          line-height: 1.4;
          padding: 0 5px;
          font-weight: 500;
        }
    }


  }

  .c_right {
    width: 200px;
    height: 100%;
    background: #FFF;
  }
}
@media screen and (max-width: 768px) {
        .content {
            flex-direction: column;

            .c_left {
              
              width: 92%;
            }
            .c_right {
            flex: none;
            width: 100%;
            margin-top: 20px;
        }
    }     
}

</style>

