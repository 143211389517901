<template>
  <div id="app-footer">
    <div id="baiduyun-footer" data-version="4.1.14">
      <div class="baiduyun-footer-copyright-wrap">
        <div class="baiduyun-footer-pc-pad">
          <div class="baiduyun-footer-row-wrap">
            <a class="">© 摆渡云 2024 版权所有</a
            ><a class="">授权域名：8yun.cc </a
            >
          </div>
          <div class="baiduyun-footer-row-wrap">
            <span
              ><a
                href="https://beian.miit.gov.cn"
                class="baiduyun-footer-clickable"
                >滇ICP备15008049号-8</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="less">
#baiduyun-footer {

    position: relative;
    width: 100%;
}

#baiduyun-footer .baiduyun-footer-pc {
    max-width: 1280px;
}
#baiduyun-footer .baiduyun-footer-pc {
    margin: 0 auto;
}

#baiduyun-footer * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #41464f;
    font-size: 14px;
}


#baiduyun-footer .baiduyun-footer-copyright-wrap {
    padding: 32px 0;
}
#baiduyun-footer .baiduyun-footer-legal-agreements, #baiduyun-footer .baiduyun-footer-row-wrap {
    -moz-box-pack: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

#baiduyun-footer .baiduyun-footer-row-wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

#baiduyun-footer .baiduyun-footer-copyright-wrap a {
    font-size: 14px;
    line-height: 28px;
    padding: 0 8px;
    text-align: center;
}

#baiduyun-footer .baiduyun-footer-copyright-wrap span {
    -moz-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
}

#baiduyun-footer .baiduyun-footer-icon {
    height: 14px;
    width: 14px;
}

#baiduyun-footer .baiduyun-footer-copyright-wrap a {
    font-size: 14px;
    line-height: 28px;
    padding: 0 8px;
    text-align: center;
}


</style>
